<template>
  <div>

    <div class="row">
      <loading-notification :show="loading" />
      <retry-notification
        :show="error"
        @retry="updateData()"
      />
    </div>

    <div class="row">
      <div class="flex xs12 sm4">
        <va-card :title="$t('reports.view')">
          <actions
            v-if="report.id != null"
            slot="actions"
            crud-links="reports"
            controller="Proyections"
            delete-route="reports/districts"
            :actions="actions"
            :action-data="{id: $route.params.id}"
          >
            <template v-if="report.id != null">
              <va-popover
                :message="$t('tables.actions.export', {format: 'Excel'})"
                placement="down"
              >
                <va-button
                  flat
                  small
                  color="success"
                  icon="fa fa-file-excel-o"
                  @click.prevent="prepareDownload('xlsx')"
                />
              </va-popover>
            </template>
          </actions>
          <text-list :title="$t('tables.headings.date')">
            {{ reportMonth() }}
          </text-list>
          <text-list
            v-if="report.district && report.district.country"
            :condition="currentUser.can('Countries', 'view') && showLinks"
            :title="$t('tables.headings.country')"
            :label="$t(report.district.country.name)"
            :to="{ name: 'countriesView', params: {id: report.district.country.id}}"
          />
          <text-list
            v-if="report.district"
            :condition="currentUser.can('Districts', 'view') && showLinks"
            :title="$t('tables.headings.district')"
            :label="$t(report.district.name)"
            :to="{ name: 'districtsView', params: {id: report.district.id}}"
          />
          <text-list
            :title="$t('tables.headings.district_approved')"
          >
            <va-badge :color="report.approval === 1 ? 'success' : 'danger'">
              {{ reportApproval(report.approval) }}
            </va-badge>
          </text-list>
          <text-list
            v-if="report.country_reports"
            :title="$t('tables.headings.country_approved')"
          >
            <va-badge v-if="report.country_reports[0]" :color="report.country_reports[0].approval === 1 ? 'success' : 'danger'">
              {{ reportApproval(report.country_reports[0].approval) }}
            </va-badge>
          </text-list>
          <text-list
            v-if="report.team"
            :condition="currentUser.can('Teams', 'view') && showLinks"
            :title="$t('tables.headings.team')"
            :label="report.team.code"
            :to="{ name: 'teamsView', params: {id: report.team.id}}"
          />
          <text-list :title="$t('tables.headings.church')">
            {{ report.mother_church }}
          </text-list>
          <text-list :title="$t('tables.headings.manager')">
            {{ report.district_manager }}
          </text-list>
        </va-card>
      </div>
      <div
        class="flex xs12 sm8"
        v-show="hasReport"
      >
        <va-card :title="$t('reports.tabs.title')">
          <tabs-container :tabs="tabs">
            <template v-slot:presentations>
              <presentations-table
                :report="report"
                :loading="loading"
              ></presentations-table>
              <text-list
                v-if="minPresentations"
                :title="$t(`reports.wizard.form.reason`)"
              >
                {{ report.observations }}
              </text-list>
            </template>
            <template v-if="report.preaching_points" v-slot:type>
              <preaching-points
                :loading="loading"
                :edit="showLinks"
                :table-data="report.preaching_points"
                :district-id="report.district_id"                
                :team="report.team_id"
                :report-id="report.id"
              />
              <!--
              <text-list :title="$t(`reports.inputs.type.name`)">
                {{ report.type_name }}
              </text-list>
              <text-list :title="$t(`reports.inputs.type.district`)">
                {{ report.type_district }}
              </text-list>
              <text-list :title="$t(`reports.inputs.type.leader`)">
                {{ report.type_minister }}
              </text-list>
              <text-list :title="$t(`reports.inputs.type.assistance`)">
                {{ report.type_asistance }}
              </text-list>
              -->
            </template>
            <template v-slot:requests>
              {{ report.testimony_es }}
              <div v-show="report.testimony_en">
                <br>
                <hr>
                <p>
                  <strong>{{ $t('layout.translation') }}:</strong>
                </p>
                <br>
                {{ report.testimony_en }}
              </div>
            </template>
          </tabs-container>
        </va-card>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import addMinutes from 'date-fns/addMinutes'
import subMinutes from 'date-fns/subMinutes'
const PresentationsTable = () => import(/* webpackPrefetch: true */ './presentations/Table')
const PreachingPoints = () => import(/* webpackPrefetch: true */ './PreachingPoints')
const TabsContainer = () => import(/* webpackPrefetch: true */ '@/components/extras/TabsContainer')
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const TextList = () => import(/* webpackPrefetch: true */ '@/components/extras/Text/TextList')

export default {
  name: 'reports-view',
  components: {
    PresentationsTable,
    PreachingPoints,
    TabsContainer,
    Actions,
    TextList,
  },
  props: {
    currentReport: {
      type: Object,
      required: false,
    },
    showLinks: {
      type: Boolean,
      default: () => true,
    },
  },
  data () {
    return {
      error: false,
      loading: false,
      report: {},
      actions: ['index', 'new', 'edit', 'delete'],
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'currentLocale']),
    reportType () {
      let type = ''

      if (Number.isInteger(this.report.type)) {
        switch (this.report.type) {
          case 0:
            type = 'reports.inputs.type.mission.title'
            break
          case 1:
            type = 'reports.inputs.type.church.title'
            break
        }
        type = this.$t(type)
      }

      return type
    },
    tabs () {
      const t = [{ title: this.$t('reports.tabs.presentations.title'), name: 'presentations' }]
      if (this.report.type !== null) {
        t.push({ title: this.$t('reports.tabs.type.title'), name: 'type' })
      }
      const test = ['testimony_es', 'testimony_en', 'request_es', 'request_en']
      if (test.some(p => this.report[p] !== null)) {
        t.push({ title: this.$t('reports.tabs.requests.main'), name: 'requests' })
      }

      return t
    },
    hasPresentations () {
      return this.report.presentations && this.report.presentations.length > 0
    },
    minPresentations () {
      return this.report.observations
    },
    hasReport () {
      return this.currentReport != null || this.report.id != null
    },
  },
  watch: {
    currentReport (val) {
      this.report = Object.assign({}, this.currentReport)
    },
    currentLocale (val) {
      this.reportMonth()
    },
  },
  created () {
    if (this.currentReport) {
      this.report = Object.assign({}, this.currentReport)
      console.log(this.report)
      return
    }

    this.updateData()
  },
  methods: {
    reportApproval (type) {
      switch (type) {
        case -1:
          type = 'tables.status.rejected'
          break
        case 1:
          type = 'tables.status.approved'
          break
        default:
          type = 'tables.status.unreviewed'
          break
      }
      type = this.$t(type)

      return type
    },
    reportMonth () {
      if (!this.report.proyection_date) return ''
      return this.$date.format(this.report.proyection_date, 'MMMM - yyyy')
    },
    // FIXME
    routeBuilder (id) {
      return `reports/districts/${id}`
    },
    async updateData () {
      this.loading = true
      this.error = false

      let u = false
      const reportId = this.$route.params.id
      try {
        u = await this.$http.get(this.routeBuilder(reportId))
      } catch (err) {
        // console.log('Error fetching country data', err)
        this.error = true
        this.loading = false
        return
      }

      this.loading = false
      this.report = u.data.data
      console.log(this.report)
    },
    async prepareDownload (format) {
      const prepareRoute = this.routeBuilder(this.report.id) + '?export=' + format
      const downloadRoute = 'proyections/download/'

      return this.downloadFile(prepareRoute, downloadRoute)
    },
    async downloadFile (prepareRoute, downloadRoute) {
      let fileName = ''
      const prepare = {
        icon: 'info',
        title: this.$t('notifications.download.prepare'),
        confirmButtonText: this.$t('notifications.download.button'),
        text: this.$t('notifications.download.wait'),
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        preConfirm: async () => {
          let data = false
          try {
            data = await this.$http.get(prepareRoute)
          } catch (e) {
            this.$swal.insertQueueStep(error)
            return
          }

          fileName = data.data.data
          this.$swal.insertQueueStep(download)
        },
      }
      const error = {
        icon: 'error',
        title: 'Error',
        text: this.$t('notifications.download.error'),
      }
      const download = {
        icon: 'success',
        title: this.$t('notifications.download.downloading'),
        allowOutsideClick: () => !this.$swal.isLoading(),
        onOpen: async () => {
          this.$swal.showLoading()
          let response = false
          try {
            response = await this.$http.get(downloadRoute + fileName, {
              responseType: 'blob',
            })
          } catch (e) {
            // console.log('Cant download file', e)
            await this.$swal.queue([retry])
            return
          }

          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()

          this.$swal.close()
        },
      }
      const retry = {
        icon: 'error',
        title: 'Error',
        text: this.$t('notifications.download.failed'),
        confirmButtonText: this.$t('notifications.download.retry'),
        allowOutsideClick: true,
        preConfirm: () => {
          this.$swal.insertQueueStep(download)
        },
      }

      this.$swal.queue([prepare])
    },
    formatTimezone (date) {
      const offset = date.getTimezoneOffset()
      return Math.sign(offset) !== -1 ? addMinutes(date, offset) : subMinutes(date, Math.abs(offset))
    },
  },
}
</script>
